/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Filter by role category
 */
export enum ROLE_CATEGORY {
  CONSUMER = "CONSUMER",
  DELEGATOR = "DELEGATOR",
  INDEXER = "INDEXER",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
